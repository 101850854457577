/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/FormPage";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ContentBuilderFragment from "@fragments/ContentBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query FormPageQuery($uri: [String]) {
    craft {
      entry(section: "formPage", uri: $uri) {
        ... on Craft_formPage_default_Entry {
          uid
          # form information
          heading: heading0
          descriptor: descriptor0
          formid: heading1
          # thank you information
          thankYouHeading: heading2
          thankYouDescriptor: descriptor1
          button: link0 {
            text
            url
          }
          link: link1 {
            text
            url
          }
          # meta
          title
          url
          metaTitle
          metaImage {
            ...ImageFragment
          }
          metaDescription
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    label,
    // content
    heading,
    descriptor,
    formid,
    thankYouHeading,
    thankYouDescriptor,
    button,
    link,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  return {
    title,
    label,
    form: { heading, descriptor, formid },
    thankYou: {
      heading: thankYouHeading,
      descriptor: thankYouDescriptor,
      button,
      link,
    },
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="FormPageDefaultQuery"
    {...props}
  />
);

export default Page;
