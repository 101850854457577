import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
// const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));
import { useAppState } from "@state";
import { AnimatePresence, m } from "framer-motion";
import classNames from "classnames";
import { Icon, Container, Button, ButtonAlt } from "@atoms";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const SectionHeading = loadable(() => import("@organisms/SectionHeading"));
const Form = loadable(() => import("@molecules/Form"));

const BasicPage = ({ data }) => {
  const [, dispatch] = useAppState();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch({ type: "setTheme", theme: "darkerTeal" });
  }, []);

  const { form, thankYou } = data;
  return (
    <PageContainer flex>
      <AnimatePresence initial={false} mode="wait">
        {!success && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="relative z-0 flex w-full flex-grow flex-col items-center justify-center gap-10 bg-black py-10 sm:gap-20 sm:py-20"
          >
            <SectionHeading
              color="bg-black"
              heading={form.heading}
              descriptor={form.descriptor}
            />
            <Container>
              <div className="relative -z-10 w-full">
                <div className="pointer-events-none absolute -right-16 -top-28 z-0 w-[12rem] -rotate-[11.5deg] md:-right-20 md:w-[16rem] lg:-right-40">
                  <Icon
                    name="logoIcon"
                    className={classNames("w-full text-teal-dark opacity-30")}
                  />
                </div>
              </div>
              <Form
                onSuccess={() => setSuccess(true)}
                formId={form.formid}
                dark
              />
            </Container>
          </m.div>
        )}
        {success && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="relative z-0 flex min-h-[66vh] w-full flex-grow flex-col items-center justify-center gap-10 bg-black py-10 sm:gap-20 sm:py-20"
          >
            <SectionHeading
              color="bg-black"
              heading={thankYou.heading}
              descriptor={thankYou.descriptor}
            />
            <Container className="flex flex-wrap items-center justify-center gap-5 text-white">
              {thankYou.button?.url && (
                <Button to={thankYou.button.url}>{thankYou.button.text}</Button>
              )}
              {thankYou.link?.url && (
                <ButtonAlt color="darkTeal" to={thankYou.link.url}>
                  {thankYou.link.text}
                </ButtonAlt>
              )}
            </Container>
          </m.div>
        )}
      </AnimatePresence>
    </PageContainer>
  );
};

export default BasicPage;
